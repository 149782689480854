@tailwind base;
@tailwind components;
@tailwind utilities;

 
@font-face {
  font-family: "Times";
  src: url("../public/fonts/Times.ttf");
}

@font-face {
  font-family: "Gotham";
  src: url("../public/fonts/GothamNarrowOffice-BoldItalic.otf");
}

@font-face {
    font-family: "Alfa";
    src: url("../public/fonts/AlfaSlabOne-Regular.ttf");
  }
  @font-face {
    font-family: "Gotham-Medium";
    src: url("../public/fonts/Gotham-Medium.otf");
  }
  @font-face {
    font-family: "Title";
    src: url("../public/fonts/Title.ttf");
  }
  @font-face {
    font-family: "RubikBubbles";
    src: url("../public/fonts/RubikBubbles-Regular.ttf");
  }
  @font-face {
    font-family: "IndieFlower";
    src: url("../public/fonts/IndieFlower-Regular.ttf");
  }

  @font-face {
    font-family: "Gotham-Medium";
    src: url("../public/fonts/Gotham-Medium.otf");
  }

  

  @font-face {
    font-family: "Lato-Medium";
    src: url("../public/fonts/Lato-Medium.ttf");
  }
  @font-face {
    font-family: "Lato-Regular";
    src: url("../public/fonts/Lato-Regular.ttf");
  }
  @font-face {
    font-family: "Lato-Bold";
    src: url("../public/fonts/Lato-Bold.ttf");
  }

.switch-field {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  font-weight: normal;
  line-height: 1.625;
}

.switch-field input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
  margin: 5px;
}

.switch-field label {
  background-color: white;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 10px 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.switch-field .attending {
  background-color: #a5dc86;
}

.switch-field .tentative {
  background-color: #787cf9;
}

.switch-field .notattending {
  background-color: #eb9090;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 4px 4px 4px 4px;
}

.switch-field label:last-of-type {
  border-radius: 4px 4px 4px 4px;
}


.mega-menu {
    display: none;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;

  }




  /* #hoverable Class Styles */
  .hoverable {
    position: static;
  }

  .hoverable > a:after {
    content: "\25BC";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
  }

  .hoverable:hover .mega-menu {
    display: block;
  }


  /* #toggle Class Styles */

  .toggleable > label:after {
    content: "\25BC";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
  }

  .toggle-input {
    display: none;
  }
  .toggle-input:not(checked) ~ .mega-menu {
    display: none;
  }

  .toggle-input:checked ~ .mega-menu {
    display: block;
  }

  .toggle-input:checked + label {
    color: white;
    background: rgb(3, 102, 114); /*@apply bg-teal-700 */
  }

  .toggle-input:checked ~ label:after {
    content: "\25B2";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
  }
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  